import { Currency } from '@commercetools/frontend-sdk/lib/types/Currency';

interface LocalizationMapping {
  locale: string;
  translationLocale: string;
  currency: Currency;
  currencyCode: string;
  countryName: string;
  countryCode: string;
  languageName?: string;
  zone?: string;
}
const legacyProjectLocales = {
  'de-de': {
    locale: 'de_DE',
    translationLocale: 'de-de',
    currency: 'EUR',
    currencyCode: '€',
    countryCode: 'DE',
    countryName: 'Germany',
    countryKey: 'de',
    languageName: 'Deutsch',
    zone: 'zone-7',
  },
  'es-es': {
    locale: 'es_ES',
    translationLocale: 'es-es',
    currency: 'EUR',
    currencyCode: '€',
    countryCode: 'ES',
    countryName: 'Spain',
    countryKey: 'es',
    languageName: 'Espanol',
    zone: 'zone-6',
  },
  'fr-fr': {
    locale: 'fr_FR',
    translationLocale: 'fr-fr',
    currency: 'EUR',
    currencyCode: '€',
    countryCode: 'FR',
    countryName: 'France',
    countryKey: 'fr',
    languageName: 'Francais',
    zone: 'zone-9',
  },
  'it-it': {
    locale: 'it_IT',
    translationLocale: 'it-it',
    currency: 'EUR',
    currencyCode: '€',
    countryCode: 'IT',
    countryName: 'Italy',
    countryKey: 'it',
    languageName: 'Italiano',
    zone: 'zone-11',
  },
  'nl-nl': {
    locale: 'nl_NL',
    translationLocale: 'nl-nl',
    currency: 'EUR',
    currencyCode: '€',
    countryCode: 'NL',
    countryName: 'Netherlands',
    countryKey: 'nl',
    languageName: 'Nederlands',
    zone: 'zone-10',
  },
  'ar-sa': {
    locale: 'ar_SA',
    translationLocale: 'ar-sa',
    currency: 'GBP',
    currencyCode: '£',
    countryCode: 'AA',
    countryName: 'Arab World',
    countryKey: 'ar',
    languageName: 'Arabic',
    zone: 'zone-13',
  },
  'pt-br': {
    locale: 'pt_BR',
    translationLocale: 'pt-br',
    currency: 'EUR',
    currencyCode: '€',
    countryCode: 'BR',
    countryName: 'Brazil',
    countryKey: 'br',
    languageName: 'Portuguese',
    zone: 'zone-12',
  },
  'th-th': {
    locale: 'th_TH',
    translationLocale: 'th-th',
    currency: 'AUD',
    currencyCode: 'A$',
    countryCode: 'TH',
    countryName: 'Thailand',
    countryKey: 'th',
    languageName: 'Thai',
    zone: 'zone-18',
  },
  'zh-cn': {
    locale: 'zh_CN',
    translationLocale: 'zh-cn',
    currency: 'AUD',
    currencyCode: 'A$',
    countryCode: 'CN',
    countryName: 'China',
    countryKey: 'zh',
    languageName: 'Chinese - Simplified',
    zone: 'zone-14',
  },
  'zh-hk': {
    locale: 'zh_HK',
    translationLocale: 'zh-hk',
    currency: 'AUD',
    currencyCode: 'A$',
    countryCode: 'HK',
    countryName: 'Hong Kong',
    countryKey: 'zh',
    languageName: 'Chinese - Traditional',
    zone: 'zone-15',
  },
  'ja-jp': {
    locale: 'ja_JP',
    translationLocale: 'ja-jp',
    currency: 'USD',
    currencyCode: '$',
    countryCode: 'JP',
    countryName: 'Japan',
    countryKey: 'ja',
    languageName: 'Japanese',
    zone: 'zone-16',
  },
  'ko-kr': {
    locale: 'ko_KR',
    translationLocale: 'ko-kr',
    currency: 'AUD',
    currencyCode: 'A$',
    countryCode: 'KR',
    countryName: 'South Korea',
    countryKey: 'ko',
    languageName: 'Korean',
    zone: 'zone-17',
  },
};

const currencies = {
  aud: {
    currency: 'AUD',
    currencyCode: 'A$',
    countryCode: 'AU',
    countryName: 'Australia',
    countryKey: 'au',
    languageName: 'English',
    zone: 'zone-4',
  },
  cad: {
    currency: 'CAD',
    currencyCode: 'CA$',
    countryCode: 'CA',
    countryName: 'Canada',
    countryKey: 'ca',
    languageName: 'English',
    zone: 'zone-2',
  },
  chf: {
    currency: 'CHF',
    currencyCode: 'CHF',
    countryCode: 'CH',
    countryName: 'Switzerland',
    countryKey: 'ch',
    languageName: 'Deutsch',
    zone: 'zone-6',
  },
  eur: {
    currency: 'EUR',
    currencyCode: '€',
    countryCode: 'DK',
    countryName: 'Denmark',
    countryKey: 'dk',
    languageName: 'English',
    zone: 'zone-5',
  },
  gbp: {
    currency: 'GBP',
    currencyCode: '£',
    countryCode: 'UK',
    countryName: 'United Kingdom',
    countryKey: 'uk',
    languageName: 'English',
    zone: 'zone-3',
  },
  usd: {
    currency: 'USD',
    currencyCode: '$',
    countryCode: 'US',
    countryName: 'United States',
    countryKey: 'us',
    languageName: 'English',
    zone: 'zone-1',
  },
};

const translationLanguages: any = {
  en: {
    locale: 'en_US',
    translationLocale: 'en-us',
    nativeName: 'English',
    twoDigitCode: 'en',
    languageCode: 'en-US',
  },
  de: {
    locale: 'de_DE',
    translationLocale: 'de-de',
    nativeName: 'Deutsch',
    twoDigitCode: 'de',
    languageCode: 'de-DE',
  },
  es: {
    locale: 'es_ES',
    translationLocale: 'es-es',
    nativeName: 'Español',
    twoDigitCode: 'es',
    languageCode: 'es-ES',
  },
  fr: {
    locale: 'fr_FR',
    translationLocale: 'fr-fr',
    nativeName: 'Français',
    twoDigitCode: 'fr',
    languageCode: 'fr-FR',
  },
  it: {
    locale: 'it_IT',
    translationLocale: 'it-it',
    nativeName: 'Italiano',
    twoDigitCode: 'it',
    languageCode: 'it-IT',
  },
  nl: {
    locale: 'nl_NL',
    translationLocale: 'nl-nl',
    nativeName: 'Nederlands',
    twoDigitCode: 'nl',
    languageCode: 'nl-NL',
  },
  ar: {
    locale: 'ar_SA',
    translationLocale: 'ar-sa',
    nativeName: 'اللغة العربية',
    twoDigitCode: 'ar',
    languageCode: 'ar-SA',
  },
  pt: {
    locale: 'pt_BR',
    translationLocale: 'pt-br',
    nativeName: 'Português',
    twoDigitCode: 'pt',
    languageCode: 'pt-PT',
  },
  th: {
    locale: 'th_TH',
    translationLocale: 'th-th',
    nativeName: 'ไทย',
    twoDigitCode: 'th',
    languageCode: 'th-TH',
  },
  cn: {
    locale: 'zh_CN',
    translationLocale: 'zh-cn',
    nativeName: '简体中文',
    twoDigitCode: 'cn',
    languageCode: 'zh-CN',
  },
  hk: {
    locale: 'zh_HK',
    translationLocale: 'zh-hk',
    nativeName: '繁體中文',
    twoDigitCode: 'hk',
    languageCode: 'zh-HK',
  },
  ja: {
    locale: 'ja_JP',
    translationLocale: 'ja-jp',
    nativeName: '日本語',
    twoDigitCode: 'ja',
    languageCode: 'ja-JP',
  },
  ko: {
    locale: 'ko_KR',
    translationLocale: 'ko-kr',
    nativeName: '한국어',
    twoDigitCode: 'ko',
    languageCode: 'ko-KR',
  },
};

export const getTranslationLanguages = () => {
  return translationLanguages;
};

export const buildi18nLocales = () => {
  const baseLocales = [];
  for (const [languageKey, language] of Object.entries(translationLanguages)) {
    for (const [currencyKey, currency] of Object.entries(currencies)) {
      baseLocales.push(`${languageKey}-${currency.countryKey}`);
    }
  }
  const legacyLocales = [];
  for (const [key, language] of Object.entries(legacyProjectLocales)) {
    legacyLocales.push(key);
  }
  const locales = [...baseLocales, ...legacyLocales];
  return locales;
};

export const buildLocalizationMapper = () => {
  const projectLocales: any = {};
  const i18nLocales = buildi18nLocales();

  i18nLocales.map((locale) => {
    const localeSplit = locale.split('-');
    const language = locale.startsWith('zh') ? localeSplit[1] : localeSplit[0];
    const country = localeSplit[1];
    for (const [key, currency] of Object.entries(currencies)) {
      let newLocale = `${language}-${currency.countryKey}`;
      if (locale.startsWith('zh')) {
        newLocale = `${country}-${currency.countryKey}`;
      }
      projectLocales[newLocale] = {
        locale: translationLanguages[language].locale,
        translationLocale: translationLanguages[language].translationLocale,
        currency: currency.currency as Currency,
        currencyCode: currency.currencyCode,
        countryCode: currency.countryCode,
        zone: currency.zone,
        countryName: currency.countryName,
      };
    }
  });
  const locales = {
    ...projectLocales,
    ...legacyProjectLocales,
  };

  return locales as Record<string, LocalizationMapping>;
};

// Keep in sync with i18n.config.ts
const localizationMapper = buildLocalizationMapper() as Record<string, LocalizationMapping>;

export const getLocalizationInfo = (locale: string) => {
  if (!(locale in localizationMapper)) {
    console.warn(
      `Invalid locale ${locale} provided. Possible values are ${Object.keys(localizationMapper).join(', ')}`,
    );

    return localizationMapper['en-us'];
  }

  return localizationMapper[locale as keyof typeof localizationMapper];
};

export const getLocaleFromZone = (zone: string) => {
  if (zone) {
    const locale = Object.keys(localizationMapper).find((c) => localizationMapper[c].zone === zone);
    if (locale) {
      return locale;
    }
  }
  return 'en-us';
};

// Used for geo modal.
export const getLocaleByZone = (language: string, zone: string) => {
  if (language && zone) {
    for (const [key, value] of Object.entries(localizationMapper)) {
      if (key.startsWith(language)) {
        if (value.zone == zone) {
          return { locale: key, zone: value.zone };
        }
      }
    }
  }
  return {
    locale: 'en-us',
    zone: 'zone-1',
  };
};

export const getUrlLocales = () => {
  const keys = Object.keys(localizationMapper);
  return keys;
};

export const getSitemapLocales = () => {
  const sitemapLocales: string[] = [];
  const locales = getUrlLocales();
  const legacyLocales = Object.keys(legacyProjectLocales);
  locales.forEach((locale: string) => {
    if (!legacyLocales.includes(locale)) sitemapLocales.push(locale);
  });
  return sitemapLocales;
};

export const getHrefLangs = () => {
  const hreflangs: string[] = [];
  const locales = getUrlLocales();
  const legacyLocales = Object.keys(legacyProjectLocales);
  locales.forEach((locale: string) => {
    // Rewrite cn- locales.
    if (locale.startsWith('cn-')) {
      const localSplit = locale.split('-');
      hreflangs.push(`zh-hans-${localSplit[1]}`);
    } else if (locale.startsWith('hk-')) {
      const localSplit = locale.split('-');
      hreflangs.push(`zh-hant-${localSplit[1]}`);
    } else {
      // Remove legacy locales.
      if (!legacyLocales.includes(locale)) hreflangs.push(locale);
    }
  });
  return hreflangs;
};
