import SfmcNewsletterForm from 'components/padi-ui/form/sfmc-newsletter';

interface SfmcPreference {
  preferenceId: string;
  status: string;
  optInQualifier: string;
  optInPromotion: string;
}

export interface ProductSfmcNewsletterProps {
  product: Record<string, any>;
  attributeSection: string;
  title: string;
  titleHtag?: string;
  body: string;
  submitButtonText: string;
  legalDisclaimer: string;
  confirmationMessage: string;
  details: object;
}

const ProductSfmcNewsletter: React.FC<ProductSfmcNewsletterProps> = ({
  product,
  attributeSection,
  title,
  titleHtag,
  body,
  submitButtonText,
  legalDisclaimer,
  confirmationMessage,
  details,
}) => {
  try {
    const productContent = JSON.parse(product?.variants?.[0].attributes[attributeSection]);
    const prefs: SfmcPreference[] = [
      {
        preferenceId: productContent?.prefId,
        status: productContent?.prefStatus,
        optInQualifier: productContent?.optinQualifier,
        optInPromotion: productContent?.optinPromotion,
      },
    ];

    return (
      <SfmcNewsletterForm
        prefs={prefs}
        title={title}
        titleHtag={titleHtag}
        body={body}
        submitButtonText={submitButtonText}
        legalDisclaimer={legalDisclaimer}
        confirmationMessage={confirmationMessage}
        details={details}
      />
    );
  } catch (e) {
    console.error('Error parsing product content:', e);
    return <></>;
  }
};

export default ProductSfmcNewsletter;
