import Badge from 'components/padi-ui/badge';
import React, { useMemo } from 'react';
import { Product } from 'shared/types/product';

interface ProductBadgeProps {
  product?: Product;
}

interface BadgeType {
  text: string;
  color: 'red' | 'blue' | 'white';
}

interface SectionMappingType {
  'certification-card': string;
  'bundle-parent': string;
  Course: string;
}

const getBadgeData = (data: string) => {
  try {
    const { color, text: body }: BadgeType = data ? JSON.parse(data) : {};
    return { color, body };
  } catch (error) {
    console.error('Error parsing dataBadge:', error);
    return {};
  }
};

const ProductBadge: React.FC<ProductBadgeProps> = ({ product }) => {
  const sectionMapping: SectionMappingType = {
    'certification-card': product?.variants?.[0].attributes?.['section-3'],
    'bundle-parent': product?.variants?.[0].attributes?.['section-6'],
    Course: product?.variants?.[0].attributes?.['section-14'],
  };

  const dataBadge = sectionMapping[product?.productType as keyof SectionMappingType] || '';
  const { color, body } = useMemo(() => getBadgeData(dataBadge), [dataBadge]);

  if (!color || !body) {
    return <></>;
  }

  return (
    <div className="absolute left-12 top-12 max-w-[224px]">
      <Badge color={color} body={body} />
    </div>
  );
};

export default React.memo(ProductBadge);
